jQuery(document).ready(function() {

    // Responsive menu

         jQuery('#hamburger').on('click', function() {
        if (jQuery('.main-nav').is(':visible')) {
            jQuery('.main-nav').fadeOut(500);
        } else {
            jQuery('.main-nav').fadeIn(10);
        }
    });

    // Services - open the hidden section
    jQuery('.process-learn-more').on('click', function() {
      jQuery(this).toggleClass('rotated');
        if (jQuery(this).hasClass('rotated')) {
            //1. Hide services
            jQuery('.services').hide();
            //2. Show section-process
             jQuery('.section-process')
               .removeClass('hidden')
               .addClass('animated bounceInRight')
               .removeClass('bounceOutRight');
        } else {
            //1. Hide section-process
            jQuery('.section-process').addClass('animated bounceOutRight');
            jQuery('.section-process').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
              if(jQuery(this).hasClass('bounceOutRight'))
                jQuery(this).addClass('hidden');
            });
            //2. Show services
            jQuery('.services').show();
        }
    });

    jQuery('.services-learn-more').on('click', function() {
      jQuery(this).toggleClass('rotated');
        if (jQuery(this).hasClass('rotated')) {
          //1. Show section-services
            jQuery('.section-services').show()
            .removeClass('hidden')
            .addClass('animated bounceInRight')
            .removeClass('bounceOutRight');
        } else {
            //1. Hide section-services
            jQuery('.section-services').addClass('animated bounceOutRight');
            jQuery('.section-services').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
              if(jQuery(this).hasClass('bounceOutRight'))
                jQuery(this).addClass('hidden');
            });
        }
    });

    // Hover effect on couch and table
    jQuery('.couch').hover(function() {
            jQuery('.choose-couch').removeClass('hidden');
        })
        .on('mouseout', function() {
            jQuery('.choose-couch').addClass('hidden');
        });

    jQuery('.table').on('mouseover', function() {
            jQuery('.choose-table').removeClass('hidden');
        })
        .on('mouseout', function() {
            jQuery('.choose-table').addClass('hidden');
        });

    //Fixed Header on Scroll above 1170px
    //if ( $( window ).width() > 1170 ) {
    jQuery(window).on('scroll', function() {
        if (jQuery(window).scrollTop() > 50) {
            jQuery('.header').addClass('scrolled');
        } else {
            jQuery('.header').removeClass('scrolled');
        }
    });
    //}

    // Scroll down button
    // Navigation
    $('.scroll-item').on('click', function() {
        var current = $(this).attr('data-item'),
            stickyHeight = $('#scroller').height();

        $('html,body').animate({
            scrollTop: $('#' + current).offset().top - stickyHeight - 13
        }, 800);

    });

    $('.scroll-down').click(function() {
        $('html, body').animate({ scrollTop: $('.section--about').offset().top - 80 }, 'slow');
    });


    // Video lightbox
    $(document).on('click', '.trigger', function(event) {
        event.preventDefault();
        $('#modal-iframe').iziModal('open'); // Use "event" to get URL href
    });

    $("#modal-iframe").iziModal({
        iframe: true,
        iframeHeight: 800,
        width: 1000,
        transitionIn: 'comingIn',
        transitionOut: 'comingOut',
        transitionInOverlay: 'fadeIn',
        transitionOutOverlay: 'fadeOut',
        iframeURL: "https://www.youtube.com/embed/vwOtYYAV_uc?list=PLyHRNrrTNzOuJW5XHTgblxZR5_nPaMCCD&amp;showinfo=0"
    });

    // Portfolio Item Galleries
    $(".iziModal").iziModal({
        headerColor: '#fff',
        width: 1000,
        group: 'portfolio-item1',
        navigateArrows: true,
        loop: true
    });

    $('.trigger-project1').on('click', function(e) {
        e.preventDefault();
        $('#modal-project1').iziModal('open');
    });

    $(".iziModal").iziModal({
        headerColor: '#fff',
        width: 1000,
        group: 'portfolio-item2',
        navigateArrows: true,
        loop: true
    });

    $('.trigger-project2').on('click', function(e) {
        e.preventDefault();
        $('#modal-project2').iziModal('open');
    });

    $(".iziModal").iziModal({
        headerColor: '#fff',
        width: 1000,
        group: 'portfolio-item3',
        navigateArrows: true,
        loop: true
    });

    $('.trigger-project3').on('click', function(e) {
        e.preventDefault();
        $('#modal-project3').iziModal('open');
    });

    $(".iziModal").iziModal({
        headerColor: '#fff',
        width: 1000,
        group: 'portfolio-item4',
        navigateArrows: true,
        loop: true
    });

    $('.trigger-project4').on('click', function(e) {
        e.preventDefault();
        $('#modal-project4').iziModal('open');
    });

        // Contact form submission
        $('#contact-form').submit(function(e){
            e.preventDefault();

            $.ajax({
                url: 'send-email.php',
                type: 'post',
                data: {
                    'user-name': $('#name').val(),
                    'email': $('#email').val(),
                    'message': $('#message').val()
                },
                success: function(res){
                    var result = $.parseJSON(res);
                    $('#result').text(result.message);
                },
                error: function(res){
                }
            })
        })

});
